import { device } from 'src/styles/breakpoints';
import { grayscale, green, orange } from 'src/styles/colors';
import styled from 'styled-components';

type LangProps = {
  typeLang?: string;
}

export const PaginationStyle = styled.nav<LangProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;
  }
  > button {
      min-width: 48px;
      min-height: 48px;
    }
    
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin-bottom: 0;

    li {
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }

      @media ${device.tablet} {
        margin-right: 16px;
      }

      button {
        font-size: 18px;
        font-weight: 700;
        color: ${grayscale[500]};
        min-width: 48px;
        min-height: 48px;
        
        &.active {
          color: white;
          border-radius: 8%;
          background-color: ${(props: LangProps) => props.typeLang === 'PJ' ? green[500] : orange.extra };
        }
      }
    }
  }
`

export const DescriptionMSG = styled.p`

  font-size: 16px;
  line-height: 20px;
  color: ${grayscale[500]};
  text-align: center;
`
