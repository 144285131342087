import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Accordeon = styled.div`
  max-height: 120px;
  padding: 15px;
  transition: max-height 0.25s;
  overflow: hidden;
  border-bottom: 1px solid ${grayscale[300]};
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.tablet} {
    max-height: 72px;
  }

  &.active {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
    overflow: visible;
  }
`

export const AccordeonTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div + {
    cursor: pointer;
    width: 32px;
    height: 32px;

    &.arrow-active {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }
`

export const Title = styled.div`
  width: 100%;
    
  @media ${device.tablet} {
    display: flex;
    align-items: center;
  }

  span {
    color: ${grayscale[500]};
  }
`

export const TitleH3 = styled.h3`
  font-size: 16px;
  line-height: 20px;
  color: ${grayscale[500]};
  font-weight: 600;
  margin-bottom: 16px;
  
  @media ${device.tablet} {
    margin-right: 40px;
    margin-bottom: 0;
  }
`

export const AccordeonContent = styled.div`
  width: 100%;
  margin-top: 40px;
`

export const InfoDataOffice = styled.div`
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    justify-content: start;
  }
`

export const LabelDataOffice = styled.label`
  color: ${grayscale[500]};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 32px;
  
  span {
    display: block;
    font-weight: 600;
    margin-top: 8px;
  }
`
