import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

import { Button } from '@interco/inter-ui/components/Button'
import { primary } from 'src/styles/newColors'

export const SectionSelectList = styled.section`
  padding: 64px 0;

  .input-select {
    min-height: 64px;
    height: 64px;
    border: 1px solid ${primary[500]};
    border-radius: 8px;

    &:hover {
      color: #fff;
      border: 1px solid ${primary[500]};
    }
  }
`

export const BottomSelect = styled.label`
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-bottom: 32px;
  }
`

export const Label = styled.label`
  color: ${grayscale[500]};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  font-weight: 600;
`

export const FilterButton = styled(Button)`
  min-height: 64px;

  @media ${device.tablet} {
    margin-top: 40px;
  }
`
