import { Link } from 'gatsby'
import React from 'react'

import IcHouse from '@interco/icons/core/action-navigation/ic_house'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { BreadSection, Description, SectionHeroAssesoria, TitleH1 } from './styles'

const HeroAssessoriaInvestimentos = () => {
  return (
    <SectionHeroAssesoria
      id='encontre-um-escritorio'
      role='img'
      aria-label='Pessoas vestindo roupas laranja e marrom mexendo em um tablet e conversando sobre assessoria de investimentos'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <BreadSection>
              <Link to='/' title='Home'>
                <IcHouse height={32} width={32} color='#ffffff' />
              </Link>
              <OrangeIcon icon='arrow-right' color='#ffffff' size='SM' />
              <Link to='/pra-voce/investimentos/' title='Investimentos Inter'>
                <span>Investimentos</span>
              </Link>
              <OrangeIcon icon='arrow-right' color='#ffffff' size='SM' />
              <span>Assessoria</span>
            </BreadSection>
            <TitleH1><span>Encontre um escritório</span> de assessoria</TitleH1>
            <Description><span>Confira aqui todos os escritórios</span> contratados pelo Inter</Description>
          </div>
        </div>
      </div>
    </SectionHeroAssesoria>
  )
}

export default HeroAssessoriaInvestimentos
