import React, { useEffect, useState } from 'react'
import { OrangeIcon } from 'src/components/UI/MarkdownIcon'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { DescriptionMSG, PaginationStyle } from './style'

const Pagination = ({ numberPages, limit, setOffset, activePage, setActivePage, clearPagination }: PaginationProps) => {
  const arrayNumbers = new Array(numberPages).fill(1).map((_: number, index: number) => index + 1)
  const SearchNotFound = 'Nenhum resultado encontrado para sua busca.'
  const [ interval, setInterval ] = useState(2)
  const width = useWidth()

  function handleClickArrow (direction: string) {
    const newActivePage = direction === 'right' ? activePage + 1 : activePage - 1
    setOffset(limit * (newActivePage - 1))
    setActivePage(newActivePage)
  }

  function handleClickNumber (activePage: number) {
    setOffset(limit * (activePage - 1))
    setActivePage(activePage)
  }

  useEffect(() => {
    if (clearPagination) {
      arrayNumbers.length = 1
    }
  }, [ clearPagination, activePage ])

  useEffect(() => {
    setInterval(width < WIDTH_MD ? 2 : 3)
  }, [ setInterval, width ])

  return (
    <>
      {
        arrayNumbers.length > 0
          ? (
            <PaginationStyle>
              { activePage - 1 > 0
              ? (
                <button className='arrows' onClick={() => handleClickArrow('left')}>
                  <OrangeIcon color='#FF7A00' icon='chevron-left' size='LG' />
                </button>
                )
              : (
                <button
                  className='arrows'
                  onClick={() => handleClickArrow('left')}
                  disabled
                >
                  <OrangeIcon color='#DEDFE4' icon='chevron-left' size='LG' />
                </button>
              )
            }
              <ul>
                {
                arrayNumbers.slice((activePage - 1) - interval, (activePage - 1)).map((item: number) => (
                  <li key={item}>
                    <button onClick={() => handleClickNumber(item)} className={`${activePage === item ? 'active' : ''}`}>
                      {item}
                    </button>
                  </li>
                ))
              }
                {
                arrayNumbers.slice((activePage - 1), (activePage - 1) + (interval + 1)).map((item: number) => (
                  <li key={item}>
                    <button onClick={() => handleClickNumber(item)} className={`${activePage === item ? 'active' : ''}`}>
                      {item}
                    </button>
                  </li>
                ))
              }
              </ul>
              { activePage < numberPages &&
                <button
                  className='arrows right'
                  onClick={() => handleClickArrow('right')}
                >
                  <OrangeIcon color='#FF7A00' icon='chevron-right' size='LG' />
                </button>}
            </PaginationStyle>
          )
        : <DescriptionMSG>{SearchNotFound}</DescriptionMSG>
      }
    </>
  )
}

export default Pagination
