/* eslint-disable @typescript-eslint/typedef */
import { MultiValue, Select, SelectOption, SingleValue } from '@interco/inter-ui/components/Select'
import React, { useCallback, useEffect, useState } from "react"

import ToggleAccordeon from "src/components/ToggleAccordeon"

import dataOffice from './../../assets/data/data-office.json'

import { BottomSelect, FilterButton, Label, SectionSelectList } from "./styles"

const SelectList = () => {
  const [ selectedState, setSelectedState ] = useState<SingleValue<SelectOption>>(null)
  const [ selectedCity, setSelectedCity ] = useState<SingleValue<SelectOption>>(null)
  const [ selectedOffice, setSelectedOffice ] = useState<SingleValue<SelectOption>>(null)
  const [ isFilter, setIsFilter ] = useState(false)
  const [ isFilteredDataOffice, setIsFilteredDataOffice ] = useState(dataOffice)
  const [ isClearPagination, setIsClearPagination ] = useState(false)

  const officeState: SelectOption[] = dataOffice.map((office: DataOfficeProps) => ({ label: office.state, value: office.state }))

  const officeCity: SelectOption[] = dataOffice
    .filter((office: DataOfficeProps) => office.state === selectedState?.value)
    .map((office: DataOfficeProps) => ({ label: office.city, value: office.city }))

  const officeOffice: SelectOption[] = dataOffice
    .filter((office: DataOfficeProps) => office.state === selectedState?.value && office.city === selectedCity?.value)
    .map((office: DataOfficeProps) => ({ label: office.registeredName, value: office.registeredName }))

  const unifiedState = Array.from(new Set(officeState.map((office: SelectOption) => office.value))).map((state) => ({
    label: String(state),
    value: state,
  }))

  const unifiedCity = Array.from(new Set(officeCity.map((office: SelectOption) => office.value))).map((city) => ({
    label: String(city),
    value: city,
  }))

  const unifiedOffice = Array.from(new Set(officeOffice.map((office: SelectOption) => office.value))).map((registeredName) => ({
    label: String(registeredName),
    value: registeredName,
  }))

  useEffect(() => {
    if (isFilter) {
      const filteredDataOffice = dataOffice.filter((office: DataOfficeProps) => {
        const stateMatch = selectedState ? office.state === selectedState.value : true
        const cityMatch = selectedCity ? office.city === selectedCity.value : true
        const officeMatch = selectedOffice ? office.registeredName === selectedOffice.value : true
        return stateMatch && cityMatch && officeMatch
      })
      setIsFilteredDataOffice(filteredDataOffice)
      setIsFilter(false)
    }
  }, [ isFilter, selectedState, selectedCity, selectedOffice ])

  const onButtonClick = useCallback(() => {
    setIsFilter(true)
  }, [ isFilteredDataOffice ])

  return (
    <SectionSelectList id='lista-de-assessoria'>
      <div className='container'>
        <div className='row'>
          <BottomSelect className='col-12 col-md-6 col-xl-3'>
            <Label>Escolha um estado</Label>
            <Select
              options={unifiedState}
              placeholder='Todos'
              onChange={(newValue: SingleValue<SelectOption> | MultiValue<SelectOption>) => {
                setSelectedState(newValue as SingleValue<SelectOption>)
                setSelectedCity(null)
                setSelectedOffice(null)
              }}
              classNames={{
                control: () => 'input-select',
              }}
            />
          </BottomSelect>
          <BottomSelect className='col-12 col-md-6 col-xl-3'>
            <Label>Escolha uma cidade</Label>
            <Select
              options={unifiedCity}
              placeholder='Todas'
              value={selectedCity}
              onChange={(newValue: SingleValue<SelectOption> | MultiValue<SelectOption>) => {
                setSelectedCity(newValue as SingleValue<SelectOption>)
                setSelectedOffice(null)
              }}
              classNames={{
                control: () => 'input-select',
              }}
            />
          </BottomSelect>
          <BottomSelect className='col-12 col-md-6 col-xl-3'>
            <Label>Escolha um escritório</Label>
            <Select
              options={unifiedOffice}
              placeholder='Todos'
              value={selectedOffice}
              onChange={(newValue: SingleValue<SelectOption> | MultiValue<SelectOption>) => setSelectedOffice(newValue as SingleValue<SelectOption>)}
              classNames={{
                control: () => 'input-select',
              }}
            />
          </BottomSelect>
          <BottomSelect className='col-12 col-md-6 col-xl-3'>
            <FilterButton
              variant='primary'
              fullWidth
              isLoading={isFilter}
              title='Buscar'
              onClick={() => {
                onButtonClick()
                setIsClearPagination(true)
              }}
            >
              Buscar
            </FilterButton>
          </BottomSelect>
          <ToggleAccordeon
            infoData={isFilteredDataOffice}
            clearPagination={isClearPagination}
          />
        </div>
      </div>
    </SectionSelectList>
  )
}

export default SelectList
