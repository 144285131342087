import React, { useState } from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'
import useWidth from "src/hooks/window/useWidth"
import { WIDTH_MD } from "src/styles/breakpoints"

import Pagination from 'src/components/Pagination/_index'

import * as S from './style'

const ToggleAccordeon = ({ infoData = [], clearPagination = false }: IDataPagination) => {
  const width = useWidth()
  const [ opened, setOpened ] = useState([ false ])
  const [ activePage, setActivePage ] = useState(1)
  const [ offset, setOffset ] = useState(0)
  const limit = 5

  function toggleAccordeon (index: number) {
    const newOpenedState = new Array(3).fill(false)
    newOpenedState[index] = true
    setOpened([ ...newOpenedState ])
    if (opened[index]) {
      setOpened([ false ])
    }
  }

  return (
    <>
      {
        infoData.slice(offset, offset + limit).map((items: DataOfficeProps, index: number) => (
          <S.Accordeon key={index} className={opened[index] ? 'active' : 'cursor-pointer'}>
            <S.AccordeonTitle onClick={() => {
              toggleAccordeon(index)
            }}
            >
              <S.Title>
                <S.TitleH3
                  title={items.registeredName}
                  dangerouslySetInnerHTML={{ __html: items.registeredName }}
                />
                <span>{items.phone}</span>
              </S.Title>
              <div className={`${opened[index] ? 'arrow-active' : ''}`}>
                <OrangeIcon icon='chevron-down' color='#161616' size='SM' />
              </div>
            </S.AccordeonTitle>
            <S.AccordeonContent className={`${index === 0 ? 'd-block' : ''}`}>
              <S.InfoDataOffice>
                {
                  width < WIDTH_MD
                    ? (
                      <div className='row'>
                        <div className='col-12 col-md-4'>
                          <S.LabelDataOffice>Assessor Responsável<span>{items.businessman}</span></S.LabelDataOffice>
                        </div>
                        <div className='col-12 col-md-4'>
                          <S.LabelDataOffice>Endereço: <span>{items.address}</span></S.LabelDataOffice>
                        </div>
                        <div className='col-12 col-md-4'>
                          <S.LabelDataOffice>UF: <span>{items.state}</span></S.LabelDataOffice>
                        </div>
                        <div className='col-12 col-md-4'>
                          <S.LabelDataOffice>Cidade: <span>{items.city}</span></S.LabelDataOffice>
                        </div>
                        <div className='col-12 col-md-4'>
                          <S.LabelDataOffice>Bairro: <span>{items.neighborhood}</span></S.LabelDataOffice>
                        </div>
                        <div className='col-12 col-md-4'>
                          <S.LabelDataOffice>Site: <span>{items.site}</span></S.LabelDataOffice>
                        </div>
                        <div className='col-12 col-md-4'>
                          <S.LabelDataOffice>
                            Lista de Assessores
                            {Array.isArray(items.partners) ? items.partners.map((partner: string, index: number) => (
                              <span key={index}>
                                {partner}
                                <br />
                              </span>
                            )) : (
                              <span key={index}>
                                {items.partners}
                                <br />
                              </span>
                            )}
                          </S.LabelDataOffice>
                        </div>
                      </div>
                    )
                    : (
                      <div className='row'>
                        <div className='col-12 col-lg-8 px-0'>
                          <div className='row mx-0'>
                            <div className='col-12 col-md-6'>
                              <S.LabelDataOffice>Assessor Responsável<span>{items.businessman}</span></S.LabelDataOffice>
                            </div>
                            <div className='col-12 col-md-6'>
                              <S.LabelDataOffice>Cidade: <span>{items.city}</span></S.LabelDataOffice>
                            </div>
                            <div className='col-12 col-md-6'>
                              <S.LabelDataOffice>Endereço: <span>{items.address}</span></S.LabelDataOffice>
                            </div>
                            <div className='col-12 col-md-6'>
                              <S.LabelDataOffice>Bairro: <span>{items.neighborhood}</span></S.LabelDataOffice>
                            </div>
                            <div className='col-12 col-md-6'>
                              <S.LabelDataOffice>UF: <span>{items.state}</span></S.LabelDataOffice>
                            </div>
                            <div className='col-12 col-md-6'>
                              <S.LabelDataOffice>Site: <span>{items.site}</span></S.LabelDataOffice>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                          <S.LabelDataOffice>
                            Lista de Assessores
                            {Array.isArray(items.partners) ? items.partners.map((partner: string, index: number) => (
                              <span key={index}>
                                {partner}
                                <br />
                              </span>
                            )) : (
                              <span key={index}>
                                {items.partners}
                                <br />
                              </span>
                            )}
                          </S.LabelDataOffice>
                        </div>
                      </div>
                    )
                  }
              </S.InfoDataOffice>
            </S.AccordeonContent>
          </S.Accordeon>
        ))
      }
      <Pagination
        activePage={activePage}
        setActivePage={setActivePage}
        numberPages={Math.ceil(infoData.length / limit)}
        limit={limit}
        setOffset={setOffset}
        clearPagination={clearPagination}
      />
    </>
  )
}

export default ToggleAccordeon
