import { initTheme, Theme } from '@interco/inter-ui'
import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import HeroAssessoriaInvestimentos from './sections/hero/_index'
import SelectList from './sections/select-list/_index'

const AssessoriaInvestimentos = () => {
  React.useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <Layout pageContext={pageContext}>
      <HeroAssessoriaInvestimentos />
      <SelectList />
    </Layout>
  )
}

export default AssessoriaInvestimentos
