import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

const BgTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/parceiros-1440/image.webp'
const BgMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/parceiros-mobile/image.webp'
const BgDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/parceiros-web/image.webp'

export const SectionHeroAssesoria = styled.section`
  background: ${orange[500]} url(${BgMobile});
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 708.33px;
  display: flex;
  align-items: end;
  padding-bottom: 40px;

  @media ${device.tablet} {
    background: ${orange[500]} url(${BgTablet});
    background-repeat: no-repeat;
    min-height: 756px;
    background-size: contain;
  }
  @media ${device.desktopLG} {
    background: ${orange[500]} url(${BgDesktop});
    background-position: right -40px center;
    background-repeat: no-repeat;
    min-height: 620px;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    background-size: contain;
  }
  @media ${device.desktopXL} {
    min-height: calc(100vh - 73px);
    background-position: right 0 center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`

export const BreadSection = styled.h1`
  margin-bottom: 32px;
  align-items: center;
  display: flex;
  justify-content: start;

  a {
    font-size: 16px;
    line-height: 20px;
    color: ${white};
    font-weight: 600;

    svg {
      margin-right: 10px;
    }
  }

  span {
    font-size: 16px;
    line-height: 20px;
    color: ${white};
    margin: 0 15px;
  }
`

export const TitleH1 = styled.h1`
  color: ${white};
  font-family: Citrina, sans-serif;
  font-size: 48px;
  line-height: 52.8px;
  margin-bottom: 32px;
  font-weight: 600;

  span {
    
    @media ${device.desktopLG} {
      display: block;
    }
  }

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 44px;
  }
  @media ${device.desktopLG} {
    margin-bottom: 16px;
  }
  @media ${device.desktopXL} {
    font-size: 48px;
    line-height: 52.8px;
  }
`

export const Description = styled.p`
  color: ${white};
  font-size: 18px;
  line-height: 22px;

  span {
    
    @media ${device.desktopLG} {
      display: block;
    }
  }
`
